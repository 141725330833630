<template>
  <div class="pageborder">
    <div class="pageback">
      <envir-page-name style="background-color: #ffffff;" :noBack="true" pageName="用户查询" />
      <div class="pagepadding">
        <el-button
          size="small"
          type="success"
          style="margin-left: 16px;margin-bottom: 16px;"
          @click="searchDialog = true">筛选设置</el-button>
        <el-button
          size="small"
          type="warning"
          style="margin-left: 16px;margin-bottom: 16px;"
          @click="newDialog = true">新增/修改用户</el-button>
        <span style="margin-left: 20px">最后对局时间</span>
        <el-date-picker
          style="margin-left: 20px"
          v-model="datevalue"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="getList()"
          :picker-options="Global.pickerOptions">
        </el-date-picker>
        <div v-loading="loading">
          <el-table
            :data="tableData"
            :row-key="row => row.id"
            style="width: 100%">
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-form label-position="left" inline class="demo-table-expand">
                  <el-form-item style="margin-left: 20px;" label="对局记录：">
                    <div v-for="item, index in props.row.gameList" :key="index">
                      {{ "单局id：" + item.id + " 对局时间： " +
                        Global.getDateNum(new Date(parseInt(item.gametimestamp)))
                      }}
                    </div>
                  </el-form-item>
                </el-form>
              </template>
            </el-table-column>
            <el-table-column label="唯一ID" prop="id"> </el-table-column>
            <el-table-column label="头像" prop="avatarurl">
              <template slot-scope="scope">
                <img :src="scope.row.avatarurl" width="48" height="48" style="margin-bottom: -8px">
              </template>
            </el-table-column>
            <el-table-column label="名称" prop="nickname"> </el-table-column>
            <el-table-column label="备注" prop="tip"> </el-table-column>
            <el-table-column label="认证状态">
              <template slot-scope="scope">
                {{scope.row.authed == 0 ? '0（未认证）' : scope.row.authed == 1 ? '1（已认证）' : '2（已取消）'}}
              </template>
            </el-table-column>
            <el-table-column label="余额" prop="money"> </el-table-column>
            <el-table-column label="认证操作">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.authed == 0 || scope.row.authed == 2"
                  size="small"
                  type="success"
                  @click="cert(scope.row.id, 1)">认证</el-button>
                <el-button
                  v-else
                  size="small"
                  type="danger"
                  @click="cert(scope.row.id, 2)">取消</el-button>
              </template>
            </el-table-column>
            <el-table-column label="资金操作" width="160">
              <template slot-scope="scope">
                <el-button
                  size="small"
                  type="success"
                  @click="addmoney(scope.row.id)">充值</el-button>
                <el-button
                  size="small"
                  type="warning"
                  @click="submoney(scope.row.id)">消费</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-top: 30px">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.page"
            :page-sizes="[5, 10, 20, 50]"
            :page-size="params.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="allamount"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="搜索内容" :visible.sync="searchDialog">
      <div v-for="(search, index1) in searchNameList" :key="index1">
        <el-input placeholder="请输入内容" v-model="searchObj[search.label]" style="margin:5px;">
          <template slot="prepend">{{search.name}}</template>
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="searchDialog = false">取 消</el-button>
        <el-button type="primary" @click="search()">搜 索</el-button>
      </div>
    </el-dialog>
    <el-dialog title="新增/修改用户" :visible.sync="newDialog" v-loading="newLoading">
      <div v-for="(user, index2) in userNameList" :key="index2">
        <el-input placeholder="请输入内容" v-model="userInfoObj[user.label]" style="margin:5px;" 
          :show-password="(user.label == 'password' || user.label == 'confirmPassword') ? true : false">
          <template slot="prepend">{{user.name}}</template>
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="newDialog = false">取 消</el-button>
        <el-button type="primary" @click="newUser()">新增/修改</el-button>
      </div>
    </el-dialog>
    <el-dialog title="输入充值金额、日期" :visible.sync="dialogaddvisible">
      <el-input placeholder="请输入金额" v-model="addamount" autocomplete="off"></el-input>
      <el-input style="margin-top: 20px;" placeholder="请输入日期，如9/23" v-model="adddate" autocomplete="off"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogaddvisible = false">取 消</el-button>
        <el-button type="primary" @click="doaddmoney()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="输入消费金额、日期" :visible.sync="dialogsubvisible">
      <el-input placeholder="请输入金额" v-model="subamount" autocomplete="off"></el-input>
      <el-input style="margin-top: 20px;" placeholder="请输入日期，如9/23" v-model="subdate" autocomplete="off"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogsubvisible = false">取 消</el-button>
        <el-button type="primary" @click="dosubmoney()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api';

export default {
  data() {
    return {
      params: {
        page: 1,
        pagesize: 10
      },
      tableData: [],
      allamount: 0,
      loading: false,
      searchObj: {},
      searchNameList: [],
      searchDialog: false,
      newLoading: false,
      userInfoObj: {},
      userNameList: [],
      newDialog: false,
      datevalue: [new Date(new Date(new Date().toLocaleDateString()).getTime()), new Date(new Date(new Date().toLocaleDateString()).getTime() + 86400000)],
      dialogaddvisible: false,
      addid: 0,
      addamount: '',
      adddate: '',
      dialogsubvisible: false,
      subid: 0,
      subamount: '',
      subdate: '',
    };
  },
  async mounted() {
    this.searchNameList = [];
    this.searchNameList.push({name: '唯一ID',label: 'id'});
    this.searchNameList.push({name: '账户名',label: 'nickname'});
    this.searchNameList.push({name: '认证状态',label: 'authed'});
    this.userNameList = [];
    this.userNameList.push({name: '唯一id，不填则新增',label: 'id'});
    this.userNameList.push({name: '认证',label: 'authed'});
    this.userNameList.push({name: '名称',label: 'nickname'});
    this.userNameList.push({name: '头像url',label: 'avatarurl'});
    this.userNameList.push({name: '余额',label: 'money'});
    this.userNameList.push({name: '备注',label: 'tip'});
    await this.getList();
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.params.pagesize = val;
      this.params.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.params.page = val;
      this.getList();
    },
    search(){
      this.searchDialog = false;
      this.params.page = 1;
      this.getList();
    },
    async getList(){
      try{
        this.loading = true;
        var postbody = {};
        if(this.params.page >= 1){
          postbody.offset = (this.params.page - 1) * this.params.pagesize;
          postbody.limit = this.params.pagesize;
        } 
        postbody.searchObj = this.searchObj;
        if(this.datevalue && this.datevalue[0] && this.datevalue[1]){
          postbody.searchObj.timerange = [this.datevalue[0].getTime(), this.datevalue[1].getTime()];
        }else{
          postbody.searchObj.timerange = undefined;
        }
        const { result, gameList } = await api.post('/searchUser', postbody);;
        console.log(result);
        for(var index in result.rows){
          result.rows[index].gameList = gameList[index];
        }
        this.tableData = result.rows;
        this.allamount = result.count;
        this.loading = false;
      }catch(e){
        console.error(e);
        this.loading = false;
      }
    },
    async newUser(){
      try{
        this.newLoading = true;
        const result = await api.post('/addUser', this.userInfoObj);
        console.log(result);
        this.$message.success('新增/修改用户成功');
        this.userInfoObj = {};
        this.newDialog = false;
        this.newLoading = false;
        await this.getList();
      }catch(e){
        console.error(e);
        this.newLoading = false;
      }
    },
    async cert(userid, type){
      try{
        this.newLoading = true;
        const result = await api.post('/certuser', {
          id: userid,
          authed: type 
        });
        this.$message.success('更改认证状态成功');
        this.newLoading = false;
        await this.getList();
      }catch(e){
        console.error(e);
        this.newLoading = false;
      }
    },
    async addmoney(id){
      this.addid = id;
      this.dialogaddvisible = true;
    },
    async doaddmoney(){
      try{
        this.newLoading = true;
        const result = await api.post('/addmoney', {
          id: this.addid,
          money: this.addamount,
          title: '充值 +' + this.addamount + (this.adddate ? '|' + this.adddate : '')
        });
        this.$message.success('增加余额成功');
        this.newLoading = false;
        this.dialogaddvisible = false;
        this.addid = 0;
        this.addamount = '';
        this.adddate = '';
        await this.getList();
      }catch(e){
        console.error(e);
        this.newLoading = false;
      }
    },
    async submoney(id){
      this.subid = id;
      this.dialogsubvisible = true;
    },
    async dosubmoney(){
      try{
        this.newLoading = true;
        const result = await api.post('/submoney', {
          id: this.subid,
          money: this.subamount,
          title: '消费 -' + this.subamount + (this.subdate ? '|' + this.subdate : '')
        });
        this.$message.success('减少余额成功');
        this.newLoading = false;
        this.dialogsubvisible = false;
        this.subid = 0;
        this.subamount = '';
        this.subdate = '';
        await this.getList();
      }catch(e){
        console.error(e);
        this.newLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.pagepadding {
  padding: 20px;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
}
.overhide{
  width: 144px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
